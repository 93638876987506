<template>
  <v-container fluid class="order-detail d-flex flex-column px-0" v-if="order">
    <CategoryTitle :category="category" />

    <div v-if="showPayment" class="pa-3 grey lighten-3 mb-5 rounded-sm">
      <span class="d-block text-h2 line-height-1 mt-3">{{
        $t("order.retryPayment")
      }}</span>
      <TigrosPaymentTypeList
        mode="order"
        :order-id="order.orderId"
        :options="paymentTypeListOptions"
      />
    </div>

    <v-row justify="space-between" class="mb-3">
      <v-col cols="12" sm="4">
        <CartInfoServiceCard
          :shippingAddress="order.shippingAddress"
          class="grey lighten-3 w-100"
        />
      </v-col>
      <!-- <v-col cols="12" sm="4" v-if="testOrder.isTest">
        <CartInfoServiceCard
          :shippingAddress="testOrder.shippingAddress"
          class="grey lighten-3 w-100"
        />
      </v-col> -->

      <v-col cols="12" sm="4">
        <CartInfoAddressCard
          :shippingAddress="order.shippingAddress"
          class="grey lighten-3 w-100"
        />
      </v-col>

      <!-- <v-col cols="12" sm="4" v-if="testOrder.isTest">
        <CartInfoAddressCard
          :shippingAddress="testOrder.shippingAddress"
          class="grey lighten-3 w-100"
        />
      </v-col> -->
      <v-col cols="12" sm="4">
        <CartInfoTimeslotCard
          v-if="order"
          :shippingAddress="order.shippingAddress"
          :timeslot="order.timeslot"
          class="grey lighten-3 w-100 cart-info-timeslot-card"
          :value="value"
        />
      </v-col>

      <!-- <v-col cols="12" sm="4" v-if="testOrder.isTest">
        <CartInfoTimeslotCard
          v-if="testOrder"
          :shippingAddress="testOrder.shippingAddress"
          :timeslot="testOrder.timeslot"
          class="grey lighten-3 w-100 cart-info-timeslot-card"
          :value="value"
        />
      </v-col> -->
    </v-row>

    <OrderDetailInfo
      class="card mb-4"
      :order="order"
      @addAllToCart="addAllToCart"
      @deleteOrder="deleteOrder"
      @editOrder="editOrder"
      @showPayment="showPayment = !showPayment"
    />
    <!-- <OrderDetailInfo
      v-if="testOrder.isTest"
      class="card mb-4"
      :order="testOrder"
      @addAllToCart="addAllToCart"
      @deleteOrder="deleteOrder"
      @editOrder="editOrder"
      @showPayment="showPayment = !showPayment"
    /> -->

    <CheckoutSummary
      :orderCart="order"
      class="card summary default--text"
      :isOrderDetail="true"
    />
    <!-- <CheckoutSummary
      v-if="testOrder.isTest"
      :orderCart="testOrder"
      class="card summary default--text"
      :isOrderDetail="true"
    /> -->

    <OrderDetailTable
      :suborders="order.suborders"
      :order="order"
      class="mt-4 mt-sm-12"
      :delivered="delivered"
    />
    <!-- <OrderDetailTable
      v-if="testOrder.isTest"
      :suborders="testOrder.suborders"
      :order="testOrder"
      class="mt-4 mt-sm-12"
      :delivered="delivered"
    /> -->

    <v-row
      no-gutters
      class="backbtn-container mt-5 justify-center justify-sm-start"
    >
      <v-btn color="primary" outlined v-bind:to="{ name: 'Orders' }">
        <v-icon class="mr-3">$prev</v-icon>
        <span v-html="$t('orders.button.backToOrders')"></span>
      </v-btn>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.order-detail {
  .card {
    padding: 10px 20px !important;
    box-sizing: border-box;
    background-color: var(--v-grey-lighten3) !important;
    border: 1px solid var(--v-grey-lighten3) !important;
    border-radius: 8px;
    &:hover {
      background-color: var(--v-primary-lighten2);
    }
    &.cart-info {
      padding: 35px 0 27px 12px !important;
      .text,
      .value {
        line-height: 24px !important;
      }
      @media #{map-get($display-breakpoints, 'sm-only')} {
        padding: 20px !important;
      }
    }
  }
  .payment-methods {
    margin-top: 0px !important;
  }
}

@media print {
  .order-detail {
    .cart-info-cards {
      flex-direction: row !important;
      gap: 12px !important;
    }

    .cart-info-cards div:first-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
<script>
// @ is an alias to /src
import OrderDetailTable from "@/components/orders/OrderDetailTable.vue";
import OrderDetailInfo from "@/components/orders/OrderDetailInfo.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import TigrosPaymentTypeList from "@/components/payment/TigrosPaymentTypeList.vue";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import categoryMixins from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapState, mapActions } from "vuex";

export default {
  name: "Order",
  data() {
    return {
      review: {
        rating: 3,
        comment: "",
        active: 1,
        title: "",
        noteId: null,
        productId: -1
      },
      order: null,
      valid: true,
      showPayment: false,
      requiredRules: [v => !!v || "Campo obbligatorio"],
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        mainBackgroundColor: "grey lighten-3",
        titleStyle: "default--text text-uppercase payment-type-list-title",
        back: {
          label: "Torna Indietro",
          class: "paymenbt-back-button",
          show: true
        }
      }
      // testOrder: {
      //   isTest: true,
      //   orderId: 171086711111111,
      //   orderStatusId: 10,
      //   orderTypeId: 1,
      //   orderStatusDescr: "Annullato",
      //   addDate: "2024-02-09T09:38:00Z",
      //   riderUserId: "",
      //   user: {
      //     userId: 200330,
      //     login: ""
      //   },
      //   timeslot: {
      //     timeslotId: 150,
      //     name: "20:00",
      //     date: "2024-02-13",
      //     beginTime: "8:00:00 PM",
      //     endTime: "10:00:00 PM"
      //   },
      //   shippingAddress: {
      //     addressId: 148326441,
      //     addressTypeId: 2,
      //     addressType: "home",
      //     deliveryServiceId: 2,
      //     zoneId: 1000034,
      //     active: 1,
      //     deliveryAddressId: 148326441,
      //     shippingAddressId: 148326441,
      //     addressName: "PATRONATO PORTA VENEZIA",
      //     address1: "Via Giuseppe Baretti",
      //     address2: "",
      //     addressNumber: "1",
      //     city: "Milano",
      //     postalcode: "20122",
      //     doorbellName: "",
      //     province: "MI",
      //     apartmentNumber: "2",
      //     maxDistance: 0.4,
      //     latitude: 45.47163949999999,
      //     longitude: 9.2048765
      //   },
      //   deliveryAddress: {
      //     addressId: 148326441,
      //     addressTypeId: 2,
      //     addressType: "home",
      //     deliveryServiceId: 2,
      //     zoneId: 1000034,
      //     active: 1,
      //     deliveryAddressId: 148326441,
      //     shippingAddressId: 148326441,
      //     addressName: "PATRONATO PORTA VENEZIA",
      //     address1: "Via Giuseppe Baretti",
      //     address2: "",
      //     addressNumber: "1",
      //     city: "Milano",
      //     postalcode: "20122",
      //     doorbellName: "",
      //     province: "MI",
      //     apartmentNumber: "2",
      //     maxDistance: 0.4,
      //     latitude: 45.47163949999999,
      //     longitude: 9.2048765
      //   },
      //   homeDelivery: true,
      //   warehouse: {
      //     warehouseId: 43,
      //     name: "Savona"
      //   },
      //   orderInfos: {},
      //   totalPrice: 89.1,
      //   totalPriceVariable: 0,
      //   netTotal: 89.1,
      //   deliveryFee: 0,
      //   packageTotal: 0,
      //   giftTotal: 0,
      //   refundTotal: 0,
      //   taxTotal: 0,
      //   grossTotal: 89.1,
      //   deliveredNetTotal: 89.1,
      //   deliveredGrossTotal: 0,
      //   deliveredGiftTotal: 0,
      //   deliveredRefundTotal: 0,
      //   deliveredPackageTotal: 0,
      //   deliveredDeliveryFee: 0,
      //   paymentTypeId: 8,
      //   isEditable: false,
      //   isDeletable: false,
      //   isParkingEnabled: false,
      //   isPayable: false,
      //   isRefundable: false,
      //   suborders: [
      //     {
      //       suborderId: 149138272,
      //       grossTotal: 89.1,
      //       suborderInfos: {},
      //       suborderItems: [
      //         {
      //           orderItemId: "171018594",
      //           quantity: 9,
      //           weight: 0,
      //           deliveredQuantity: 0,
      //           deliveredWeight: 0,
      //           deliveredGrossTotal: 0,
      //           grossTotal: 89.1,
      //           unitPrice: 9.9,
      //           product: {
      //             available: 0,
      //             productId: 148000002,
      //             codInt: "148000002",
      //             codVar: "1",
      //             code: "148000002",
      //             codeVariant: "1",
      //             name: "Offerta TELEFONIA 1",
      //             shortDescr: "Descrizione breve dell'offerta Telefonia 1",
      //             description: "",
      //             pubState: 1,
      //             ivaCategory: {
      //               ivaCategoryId: 23,
      //               code: "22-N",
      //               descr: "",
      //               ivaPct: "0.22"
      //             },
      //             price: 9.9,
      //             priceId: 45504577,
      //             priceListName: "Home Delivery Assago",
      //             priceListId: 4,
      //             priceDisplay: 9.9,
      //             priceUnitDisplay: "pz",
      //             slug: "148000002_offerta-telefonia-1",
      //             categoryId: -1,
      //             mediaURL:
      //               "https://www.tigros.it/photo2/2020/05/27/0/main/thumb/foto-telefonia_1.png",
      //             mediaURLMedium:
      //               "https://www.tigros.it/photo2/2020/05/27/0/main/photo/foto-telefonia_1.png",
      //             dayLock: "",
      //             vendor: {
      //               vendorId: 0
      //             },
      //             productClasses: [
      //               {
      //                 productClassId: 10536,
      //                 productClassGroupId: 10002,
      //                 name: "OFFERTA DEL MESE",
      //                 description: "2",
      //                 iconSource:
      //                   "/img_layout/product_classes/offerta-mese.svg",
      //                 productClassTypeId: 2
      //               }
      //             ],
      //             selectOptions: [],
      //             productInfos: {
      //               MINACQ: "1",
      //               WEB_ENABLED: "1",
      //               CHECKED: "true",
      //               TIPOLOGIA: "Pezzo"
      //             },
      //             hasDetails: false
      //           },
      //           orderItemInfos: {}
      //         }
      //       ]
      //     }
      //   ]
      // }
    };
  },
  mixins: [categoryMixins, reload],
  components: {
    OrderDetailInfo,
    OrderDetailTable,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartInfoServiceCard,
    CheckoutSummary,
    CategoryTitle,
    TigrosPaymentTypeList
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    // canIRateOrder() {
    //   return this.order.orderStatusId === 8 || this.order.orderStatusId === 18;
    // },
    enableSave() {
      return !this.valid;
    },
    serviceImg() {
      return this.$t(
        "navbar.service." +
          this.order.shippingAddress.deliveryServiceId +
          ".icon",
        {
          color: "color"
        }
      );
    },
    value() {
      if (this.order.timeslot) {
        let date = this.$dayjs(this.order.timeslot.date);
        return (
          "Da " +
          date.format("ddd D MMMM") +
          " nella fascia oraria " +
          this.$dayjs(this.order.timeslot.beginTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm") +
          " - " +
          this.$dayjs(this.order.timeslot.endTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm")
        );
      }
      return this.$t("navbar.noTimeslot");
    },
    testValue() {
      if (this.testOrder.timeslot) {
        let date = this.$dayjs(this.testOrder.timeslot.date);
        return (
          "Da " +
          date.format("ddd D MMMM") +
          " nella fascia oraria " +
          this.$dayjs(this.order.timeslot.beginTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm") +
          " - " +
          this.$dayjs(this.order.timeslot.endTime, [
            "h:mm:ss A",
            "H:mm"
          ]).format("HH:mm")
        );
      }
      return this.$t("navbar.noTimeslot");
    },
    delivered() {
      return (
        this.order.orderStatusId == 6 ||
        this.order.orderStatusId == 8 ||
        this.order.orderStatusId == 18
      );
    }
  },
  methods: {
    // getTimeslotTime(time) {
    //   if (time) {
    //     const parseTime = this.am_pm_to_hours(time);
    //     var d = new Date();
    //     var timeMatch = parseTime.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    //     d.setHours(parseInt(timeMatch[1]) + (timeMatch[3] ? 12 : 0));
    //     d.setMinutes(parseInt(timeMatch[2]) || 0);
    //     const timeOnly = this.$dayjs(d).format("HH:mm");
    //     return timeOnly;
    //   } else return "";
    // },
    // am_pm_to_hours(time) {
    //   var hours = Number(time.match(/^(\d+)/)[1]);
    //   var minutes = Number(time.match(/:(\d+)/)[1]);
    //   var AMPM = time.match(/\s(.*)$/)[1];
    //   if (AMPM == "PM" && hours < 12) hours = hours + 12;
    //   if (AMPM == "AM" && hours == 12) hours = hours - 12;
    //   var sHours = hours.toString();
    //   var sMinutes = minutes.toString();
    //   if (hours < 10) sHours = "0" + sHours;
    //   if (minutes < 10) sMinutes = "0" + sMinutes;
    //   return sHours + ":" + sMinutes;
    // },
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    async addAllToCart({ orderId }) {
      let res = await global.vm.$dialog.confirm({
        text: global.vm.$t("message.addAllFromOrder")
      });
      if (res) {
        await this.addProductsFromOrder(orderId);
      }
    },
    async deleteOrder({ orderId }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeOrder", {
          orderId: orderId
        })
      });

      if (res) {
        await OrderService.deleteOrder(orderId);
        AnalyticsService.refund(this.order);
        await this.reload(orderId);
      }
    },
    async editOrder({ orderId }) {
      try {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.confirmOrderEdit")
        });
        if (res) {
          await OrderService.editOrder(orderId);
          this.loadCart();
          this.$router.push({
            name: "Checkout"
          });
        }
      } catch (err) {
        global.EventBus.$emit("error", {
          error: err,
          message: global.EventBus.$t("errors.errorOnOrderEditConfirm")
        });
        return null;
      }
    },
    async reload(orderId) {
      this.order = await OrderService.getOrder(orderId);
      if (this.order.isPayable) {
        this.showPayment = true;
      }
    }
  },
  created() {
    this.reload(this.$route.params.orderId);
  }
};
</script>
